import React, { useEffect, useState } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { format } from "date-fns";
import it from "date-fns/locale/it";
import axios from "axios"; // Importa axios
import { useDispatch, useSelector } from "react-redux";
import { baseURL, getBookingsByRoom } from "../api";
import { useNavigate } from "react-router-dom";
import { RoomImagesSection, RoomDescription } from "../components";
import { services } from "../utils/styles";
import { FaUserAlt } from "../assets/icons/index";

const RoomSection = ({
  roomId,
  roomName,
  description,
  roomType,
  pricePerNight,
  priceForGuests,
  maxGuests,
  amenities,
  images,
}) => {
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [disableDates, setDisableDates] = useState([]);
  const [guestCount, setGuestCount] = useState(1); // Stato per il conteggio degli ospiti
  const [totalPrice, setTotalPrice] = useState(pricePerNight);

  // Funzione per calcolare il prezzo in base agli ospiti
  const calculateTotalPrice = () => {
    // Calcola il numero di notti
    const numberOfNights = Math.max(
      1,
      Math.ceil((range[0].endDate - range[0].startDate) / (1000 * 60 * 60 * 24))
    );

    // Calcola il prezzo per gli ospiti
    const priceForGuestsCount = priceForGuests[guestCount] || priceForGuests[1]; // Fallback al prezzo per 1 ospite se non definito per il numero scelto

    // Calcola il prezzo totale (notte * prezzo per ospite)
    const total = numberOfNights * priceForGuestsCount;
    setTotalPrice(total); // Imposta il prezzo totale
  };

  // Funzione per aumentare il numero di ospiti
  const incrementGuestCount = () => {
    if (guestCount < maxGuests) {
      setGuestCount(guestCount + 1);
    }
  };

  // Funzione per diminuire il numero di ospiti
  const decrementGuestCount = () => {
    if (guestCount > 1) {
      setGuestCount(guestCount - 1);
    }
  };

  // Esegui il calcolo del prezzo ogni volta che cambia il numero di ospiti o l'intervallo di date
  useEffect(() => {
    calculateTotalPrice();
  }, [guestCount, range]);

  // Recupera i dati dell'utente dallo stato di Redux
  const userData = useSelector((state) => state.user);
  const roomData = useSelector((state) =>
    state.rooms.rooms.find((room) => room.roomId === roomId)
  );

  // Effetto per aggiornare i dati ogni volta che cambia il roomId
  // Effetto per aggiornare i dati ogni volta che cambia il roomId
  useEffect(() => {
    // Reset dello stato ogni volta che cambia la stanza
    setRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    setGuestCount(1);
    setTotalPrice(pricePerNight);

    const fetchBookings = async () => {
      try {
        const response = await getBookingsByRoom(roomId);

        if (response && Array.isArray(response)) {
          const bookings = response;
          const today = new Date();
          today.setHours(0, 0, 0, 0);

          const datesToDisable = bookings.flatMap((booking) => {
            const { startDate, endDate } = booking;
            if (!startDate || !endDate) return [];

            const start = new Date(startDate);
            const end = new Date(endDate);
            if (start >= today) {
              const dateRange = [];
              let currentDate = new Date(start);
              while (currentDate <= end) {
                dateRange.push(new Date(currentDate));
                currentDate.setDate(currentDate.getDate() + 1);
              }
              return dateRange;
            }
            return [];
          });

          setDisableDates(datesToDisable);
        }
      } catch (error) {
        console.error("Errore nel recuperare le prenotazioni", error);
      }
    };

    // Richiamare la funzione per aggiornare i dati quando cambia la stanza
    fetchBookings();
  }, [roomId, pricePerNight]); // Dipendenza da roomId e pricePerNight

  // Effetto per ricalcolare il prezzo ogni volta che cambia il numero di ospiti o le date
  useEffect(() => {
    calculateTotalPrice();
  }, [guestCount, range]);

  const numberOfNights = Math.max(
    1,
    Math.ceil((range[0].endDate - range[0].startDate) / (1000 * 60 * 60 * 24))
  ); // Supponiamo che l'utente sia memorizzato in state.user

  const formatDate = (date) => {
    return format(date, "dd-MM-yyyy"); // Formatta la data come "giorno-mese-anno"
  };

  // Funzione per inviare la prenotazione
  const handleBooking = async () => {
    if (!userData || !userData.uid) {
      // Se l'utente non è loggato, reindirizza alla pagina di login
      navigate("/login"); // Modifica l'URL in base alla tua struttura
      return;
    }
    try {
      // Calcoliamo le date e il totale della prenotazione
      const startDate = formatDate(new Date(range[0].startDate));
      const endDate = formatDate(new Date(range[0].endDate));
      const totalAmount = totalPrice;

      // Crea l'oggetto metadata con le informazioni necessarie per Stripe
      const metadata = {
        userId: userData.uid,
        roomName: roomData.roomName,
        roomType: roomData.roomType,
        totalAmount,
        startDate,
        endDate,
        maxGuests: roomData.maxGuests,
      };

      // Crea il payload da inviare al server
      const payload = {
        userId: userData.uid,
        roomId: roomData.roomId,
        roomName: roomData.roomName,
        roomType: roomData.roomType,
        totalAmount,
        startDate,
        endDate,
        maxGuests: roomData.maxGuests,
        metadata, // Aggiungi metadata per passare tutte le informazioni utili
        created: new Date().toISOString(),
      };

      // Effettua la chiamata API al tuo endpoint per creare la sessione di pagamento
      const response = await axios
        .post(`${baseURL}/api/rooms/create-checkout-session`, { data: payload })
        .then((res) => {
          if (res.data.url) {
            window.location.href = res.data.url;
          }
        });

      // Esegui eventuali azioni dopo la risposta (es. redirect o conferma)
    } catch (error) {
      console.error("Errore nella prenotazione:", error);
    }
  };

  const getMinDate = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Imposta l'ora a mezzanotte
    return today;
  };

  // Effettua il calcolo ogni volta che guestCount o range cambiano

  const renderAmenities = (amenities) => {
    return amenities.map((amenity, index) => {
      // Trova l'icona corrispondente nei servizi
      const service = services.find((service) => service.title === amenity);

      if (service) {
        const IconComponent = service.icon; // Recupera il componente dell'icona
        return (
          <div
            key={index}
            className="flex items-center gap-2 p-1 border bg-headingColor rounded-xl shadow-md"
          >
            <IconComponent size={30} className="text-primary" />
            <span className="text-primary text-sm font-semibold">
              {service.title}
            </span>
          </div>
        );
      } else {
        // Se l'amenity non ha un'icona corrispondente
        return (
          <div
            key={index}
            className="flex items-center gap-2 p-2 border rounded-xl shadow-md"
          >
            <span>{amenity}</span>
          </div>
        );
      }
    });
  };

  return (
    <section className="my-8">
      <RoomImagesSection
        images={images}
        roomType={roomType}
        roomName={roomName}
      />

      <div className="flex flex-col lg:flex-col gap-6 bg-lightOverlay p-4 lg:p-8 shadow-md rounded-xl">
        <div className="flex flex-col lg:flex-row gap-4">
          <RoomDescription
            description={description}
            maxGuests={maxGuests}
            amenities={amenities}
          />

          <div className="lg:w-1/2 w-full h-full">
            <div className="flex flex-col p-4 y bg-white shadow-md rounded-xl mb-4">
              <div>
                <span className="block text-headingColor text-2xl mb-2 font-bold">
                  Servizi
                </span>
              </div>

              <div className="grid grid-cols-2 gap-2  ">
                {renderAmenities(amenities)}
              </div>
            </div>
            <div className="flex flex-col p-8 y bg-white shadow-md rounded-xl mb-4">
              <h2 className="font-medium text-2xl">Prenotati</h2>

              <h3 className="text-xl font-bold text-textColor">
                €{totalPrice}{" "}
                <span className="font-light text-gray-500">/per Notte</span>
              </h3>

              <div className="flex w-full justify-between items-center mt-4 bg-primary rounded-full p-2">
                <button
                  onClick={() => {
                    if (guestCount > 1) {
                      const newGuestCount = guestCount - 1;
                      setGuestCount(newGuestCount);
                    }
                  }}
                  className=" bg-red-500 hover:scale-105 p-2 h-10 w-10  text-2xl flex justify-center items-center shadow-inner text-white rounded-full"
                >
                  <span>-</span>
                </button>

                <span className="text-xl font-semibold">
                  Ospiti:{" "}
                  {[...Array(guestCount)].map((_, index) => (
                    <FaUserAlt
                      className="inline-block"
                      key={index}
                      style={{ margin: "0 5px" }}
                    />
                  ))}
                </span>

                <button
                  onClick={() => {
                    if (guestCount < maxGuests) {
                      const newGuestCount = guestCount + 1;
                      setGuestCount(newGuestCount);
                    }
                  }}
                  className="hover:scale-105 p-2 h-10 w-10 bg-emerald-600 text-2xl flex justify-center items-center shadow-inner text-white rounded-full"
                >
                  <span className="text-2xl ">+</span>
                </button>
              </div>

              <p className="mt-4 text-sm mb-2">Seleziona le Date</p>
              <div className="flex  w-full">
                <DateRange
                  locale={it}
                  onChange={(item) => setRange([item.selection])}
                  minDate={getMinDate()}
                  moveRangeOnFirstSelection={false}
                  ranges={range}
                  editableDateInputs={true}
                  disabledDates={disableDates} // Passa le date disabilitate
                  className="shadow-md rounded-xl custom-calendar w-full"
                  style={{ width: "100%", margin: "0 auto" }}
                />
              </div>

              <div className="mt-6 p-2 rounded-xl bg-primary shadow-inner border border-gray-200">
                <div className="text-center p-2 flex justify-around flex-wrap items-center text-gray-800 text-lg md:text-xl font-medium">
                  <div className="flex justify-center">
                    <div className="ml-2 text-xl text-center inline-block shadow-md p-2 mb-2 text-white bg-headingColor rounded-xl ">
                      {[...Array(guestCount)].map((_, index) => (
                        <FaUserAlt
                          className="inline-block"
                          key={index}
                          style={{ margin: "0 5px" }}
                        />
                      ))}
                    </div>
                  </div>

                  <div className="text-lighttextGray rounded-xl flex-wrap  flex items-center font-semibold text-xl ">
                    <div className="rounded-xl font-bold text-lighttextGray text-xl  mr-2">
                      Totale:{" "}
                    </div>
                    <div className="rounded-full p-2 w-20 bg-emerald-600 mr-1 text-white font-semibold text-xl ">
                      {" "}
                      €{totalPrice}
                    </div>{" "}
                    Per/{" "}
                    <div className="flex items-center justify-center rounded-full w-8 h-8 mr-1 bg-secondary">
                      <span className="text-2xl text-white">
                        {numberOfNights}
                      </span>
                    </div>
                    {numberOfNights === 1 ? "Notte" : "Notti"}
                  </div>
                </div>
              </div>

              <div
                onClick={handleBooking}
                className="w-full text-xl text-center mt-4 p-4 bg-emerald-600 text-white rounded-xl shadow-md cursor-pointer"
              >
                Continua alla Prenotazione
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RoomSection;
