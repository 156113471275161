import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllReviews } from "../api";
import { setAllReviews } from "../context/actions/reviewAction";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

const ReviewsSlider = () => {
  const dispatch = useDispatch();
  const reviews = useSelector((state) => state.reviews.reviews || []);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const data = await getAllReviews();
        dispatch(setAllReviews(data));
      } catch (error) {
        console.error("Errore nel recupero delle recensioni:", error);
      }
    };
    fetchReviews();
  }, [dispatch]);

  const renderStars = (rating) => {
    return Array.from({ length: 5 }, (_, index) => (
      <span
        key={index}
        className={`text-3xl ${
          index < rating ? "text-yellow-500 text-lg" : "text-gray-300"
        }`}
      >
        ★
      </span>
    ));
  };

  return (
    <div className="md:w-full overflow-hidden ">
      <h2 className="text-3xl font-bold text-headingColor mb-2">
        Recensioni dei Nostri Ospiti
      </h2>
      <p className="mb-2 text-2xl">
        Scopri cosa dicono i nostri ospiti! Le loro esperienze e opinioni ti
        aiuteranno a capire perché il nostro B&B è la scelta ideale per il tuo
        soggiorno a Lamezia Terme.
      </p>

      <div className="cursor-pointer p-4md:w-full overflow-hidden ">
        <Swiper
          spaceBetween={20}
          slidesPerView={1} // Show 3 reviews at a time
          pagination={{ clickable: true }}
          autoplay={{ delay: 3000, disableOnInteraction: false }} // Autoplay configuration
          modules={[Pagination]} // Pagination module
          className="mySwiper"
        >
          {reviews.slice(0, 3).map((review) => (
            <SwiperSlide
              key={review.id}
              className="p-6 bg-white bg-opacity-90 shadow-md rounded-xl flex justify-center flex-col items-center"
            >
              <h3 className="text-headingColor font-semibold text-2xl mb-2">
                {review.name}
              </h3>
              <div className="flex text-3xl mb-2">
                {renderStars(review.rating)}
              </div>
              <p className="text-center text-xl text-lighttextGray mb-2">
                {review.message}
              </p>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default ReviewsSlider;
