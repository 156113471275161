import React from "react";
import { useSelector } from "react-redux";
import RoomCard from "./RoomCard"; // Verifica se RoomCard è un export default

const RoomAvailable = () => {
  const rooms = useSelector((state) => state.rooms.rooms);

  return (
    <div>
      <h2 className="text-3xl font-bold text-headingColor">Le Nostre Camere</h2>
      <p className="mb-6 text-xl">
      Ogni camera è pensata per offrirti il massimo comfort, con dettagli curati e servizi moderni. Che tu stia cercando un luogo per rilassarti o per lavorare, troverai l’ambiente ideale per il tuo soggiorno.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {rooms.length > 0 ? (
          rooms.map((room) =>
            room ? (
              <div
                key={room.roomId}
                className="cursor-pointer shadow-md bg-transparent"
              >
                <RoomCard room={room} />
              </div>
            ) : null
          )
        ) : (
          <p>Nessuna stanza disponibile.</p>
        )}
      </div>
    </div>
  );
};

export default RoomAvailable;
