import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { MetaDescription } from "../components";
import {
  sanTeodoro,
  teatroUmberto,
  piazzaMazzini,
  piazzaDarmiAnni90,
  piazzaDarmiAnni80,
  fontanaPiazzaDarmi,
  corsoNumistrannoAnni80,
  corsoNumistrannoAnni50,
  centroStoricoNicastro,
  cattedrale,
  castelloNormannoNotte,
  bastioneMalta2,
  bastioneMalta,
  frontePiatto,
  fronteAngolo,
  fronteGrandangolo,
  corridoio,
  cucina,
  pianerottolo,
  poltrone,
  salaColazione,
  salaColazione2,
  salaColazione3,
  salaColazione4,
  salaColazione5,
  scale,
  scale2,
} from "../assets";

// Unisci i due array di immagini
const images = [
  // Primo array
  {
    url: sanTeodoro,
    alt: "Quartiere San Teodoro, Lamezia Terme",
    title: "Quartiere San Teodoro",
    description:
      "Il quartiere San Teodoro di Lamezia Terme è una zona vivace e dinamica, caratterizzata da un mix di tradizione e modernità, che riflette lo spirito della città.",
  },
  {
    url: teatroUmberto,
    alt: "Teatro Umberto, Lamezia Terme",
    title: "Teatro Umberto",
    description:
      "Il Teatro Umberto è un'imponente struttura culturale di Lamezia Terme, conosciuto per la sua storicità e per gli eventi teatrali e musicali che ospita.",
  },
  {
    url: piazzaMazzini,
    alt: "Piazza Mazzini, Lamezia Terme",
    title: "Piazza Mazzini",
    description:
      "Piazza Mazzini è una delle piazze principali di Lamezia Terme, cuore pulsante della vita cittadina, circondata da edifici storici e negozi.",
  },
  {
    url: piazzaDarmiAnni90,
    alt: "Piazza D'Armi negli anni '90, Lamezia Terme",
    title: "Piazza D'Armi negli anni '90",
    description:
      "Piazza D'Armi negli anni '90, una delle piazze più suggestive di Lamezia Terme, che mostra come la città si sia evoluta nel tempo, mantenendo la sua autenticità.",
  },
  {
    url: piazzaDarmiAnni80,
    alt: "Piazza D'Armi negli anni '80, Lamezia Terme",
    title: "Piazza D'Armi negli anni '80",
    description:
      "Un'immagine storica di Piazza D'Armi negli anni '80, che testimonia il dinamismo e le trasformazioni sociali della città durante quel periodo.",
  },
  {
    url: fontanaPiazzaDarmi,
    alt: "Fontana di Piazza D'Armi, Lamezia Terme",
    title: "Fontana di Piazza D'Armi",
    description:
      "La fontana di Piazza D'Armi è uno degli elementi distintivi della piazza, un luogo di ritrovo e di bellezza che riflette la tradizione architettonica di Lamezia Terme.",
  },
  {
    url: cattedrale,
    alt: "Cattedrale di Lamezia Terme",
    title: "Cattedrale di Lamezia Terme",
    description:
      "La Cattedrale di Lamezia Terme, dedicata a San Pietro e Paolo, è un simbolo della fede e della storia religiosa della città, con la sua maestosa facciata e la sua posizione centrale.",
  },
  {
    url: corsoNumistrannoAnni80,
    alt: "Corso Numistranno negli anni '80, Lamezia Terme",
    title: "Corso Numistranno negli anni '80",
    description:
      "Corso Numistranno negli anni '80, una delle arterie principali di Lamezia Terme, che racconta la crescita della città attraverso gli anni.",
  },
  {
    url: corsoNumistrannoAnni50,
    alt: "Corso Numistranno negli anni '50, Lamezia Terme",
    title: "Corso Numistranno negli anni '50",
    description:
      "Una vista storica di Corso Numistranno negli anni '50, testimoniando le prime fasi di modernizzazione della città.",
  },
  {
    url: centroStoricoNicastro,
    alt: "Centro storico di Nicastro, Lamezia Terme",
    title: "Centro storico di Nicastro",
    description:
      "Il centro storico di Nicastro è il cuore culturale di Lamezia Terme, con le sue stradine caratteristiche e il suo ricco patrimonio storico.",
  },
  {
    url: castelloNormannoNotte,
    alt: "Castello Normanno di Lamezia Terme di notte",
    title: "Castello Normanno di notte",
    description:
      "Il Castello Normanno di Lamezia Terme di notte, una delle principali attrazioni turistiche della città, che offre una vista spettacolare e un'atmosfera magica.",
  },
  {
    url: bastioneMalta2,
    alt: "Bastione di Malta, Lamezia Terme",
    title: "Bastione di Malta",
    description:
      "Il Bastione di Malta, una fortificazione storica che testimonia il passato difensivo della città, oggi simbolo di Lamezia Terme.",
  },
  {
    url: bastioneMalta,
    alt: "Bastione di Malta, Lamezia Terme",
    title: "Bastione di Malta",
    description:
      "Il Bastione di Malta, con la sua imponente struttura, è un importante sito storico che rappresenta la resistenza della città nei secoli passati.",
  },
  {
    url: frontePiatto,
    alt: "Vista frontale del B&B a Lamezia Terme",
    title: "Vista Frontale del B&B",
    description:
      "Una vista frontale che mostra l'ingresso principale del B&B situato a Lamezia Terme.",
  },
  {
    url: fronteAngolo,
    alt: "Vista frontale angolare del B&B a Lamezia Terme",
    title: "Vista Frontale Angolare del B&B",
    description:
      "Una prospettiva angolare che evidenzia i dettagli architettonici della struttura.",
  },
  {
    url: fronteGrandangolo,
    alt: "Vista grandangolare della facciata del B&B",
    title: "Vista Grandangolare della Facciata",
    description:
      "Un'immagine grandangolare che cattura l'intera facciata del B&B.",
  },
  {
    url: cucina,
    alt: "Cucina attrezzata del B&B",
    title: "Cucina del B&B",
    description:
      "Un'immagine che mostra la cucina completamente attrezzata a disposizione degli ospiti.",
  },
  {
    url: pianerottolo,
    alt: "Pianerottolo del B&B con dettagli eleganti",
    title: "Pianerottolo del B&B",
    description:
      "Un dettaglio del pianerottolo, caratterizzato da un design accogliente ed elegante.",
  },
  {
    url: poltrone,
    alt: "Area relax con poltrone confortevoli",
    title: "Area Relax del B&B",
    description:
      "Un'accogliente area relax con poltrone confortevoli per gli ospiti.",
  },
  {
    url: salaColazione,
    alt: "Sala colazione luminosa e accogliente",
    title: "Sala Colazione del B&B",
    description:
      "Un'immagine della sala colazione, perfetta per iniziare la giornata con un pasto delizioso.",
  },
  {
    url: salaColazione2,
    alt: "Dettaglio della sala colazione con tavoli apparecchiati",
    title: "Dettaglio Sala Colazione",
    description: "Tavoli apparecchiati con cura nella sala colazione del B&B.",
  },
  {
    url: salaColazione3,
    alt: "Vista panoramica della sala colazione",
    title: "Vista Panoramica Sala Colazione",
    description:
      "Una vista completa della sala colazione, che mostra lo spazio accogliente e ben organizzato.",
  },
  {
    url: salaColazione4,
    alt: "Angolo della sala colazione con buffet",
    title: "Angolo Buffet Sala Colazione",
    description:
      "Un angolo della sala colazione con un buffet ricco e variegato per gli ospiti.",
  },
  {
    url: salaColazione5,
    alt: "Dettaglio di un tavolo apparecchiato nella sala colazione",
    title: "Tavolo Apparecchiato nella Sala Colazione",
    description:
      "Un dettaglio di un tavolo ben apparecchiato, pronto per accogliere gli ospiti.",
  },
  {
    url: scale,
    alt: "Scalinata interna del B&B",
    title: "Scalinata Interna",
    description:
      "Un'immagine della scalinata interna che collega i diversi livelli del B&B.",
  },
  {
    url: scale2,
    alt: "Dettaglio della scalinata con decorazioni",
    title: "Dettaglio della Scalinata",
    description:
      "Un dettaglio della scalinata interna con eleganti decorazioni.",
  },
  {
    url: corridoio,
    alt: "Corridoio del B&B con luci soffuse",
    title: "Corridoio del B&B",
    description:
      "Un corridoio luminoso e ben arredato, che conduce alle stanze del B&B.",
  },
];

const Gallery = () => {
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleImageClick = (index) => {
    setCurrentIndex(index);
    setIsLightboxOpen(true);
  };

  return (
    <div className="max-w-7xl">
      <MetaDescription
        title="Scopri il B&B Piazza d'Armi | La Gallery"
        description="Esplora la gallery del B&B Piazza d'Armi a Lamezia Terme. Immagini delle nostre camere, spazi comuni e dintorni per il tuo soggiorno perfetto."
        image="./public/images/fronte-piatto.jpg"
        url="https://www.bebpiazzadarmi.it/gallery"
      />
      <div className=" bg-white py-10 px-6 rounded-xl shadow-lg mb-2 ">
        <h1 className="text-3xl md:text-4xl text-center font-bold text-headingColor">
          Le Nostra Gallery
        </h1>
      </div>
      {/* Griglia delle immagini */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        {images.map((image, index) => (
          <div
            key={index}
            className="relative group cursor-pointer"
            onClick={() => handleImageClick(index)}
          >
            {/* Immagine */}
            <img
              src={image.url}
              alt={image.alt}
              className="w-full h-64 object-cover rounded-xl shadow-lg hover:opacity-80 transition"
            />
            {/* Sovrapposizione con descrizione */}
            <div className="absolute inset-0 bg-black bg-opacity-60 opacity-0 group-hover:opacity-100 transition flex flex-col items-center justify-center rounded">
              <h2 className="text-white text-lg font-bold text-center px-2">
                {image.title}
              </h2>
              <p className="text-white text-sm text-center px-4">
                {image.description}
              </p>
            </div>
          </div>
        ))}
      </div>

      {/* Lightbox */}
      {isLightboxOpen && (
        <Lightbox
          mainSrc={images[currentIndex].url}
          nextSrc={images[(currentIndex + 1) % images.length]?.url}
          prevSrc={
            images[(currentIndex + images.length - 1) % images.length]?.url
          }
          onCloseRequest={() => setIsLightboxOpen(false)}
          onMovePrevRequest={() =>
            setCurrentIndex((currentIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setCurrentIndex((currentIndex + 1) % images.length)
          }
          imageCaption={
            <div>
              <h2 className="text-lg font-bold">
                {images[currentIndex]?.title}
              </h2>
              <p className="text-sm">{images[currentIndex]?.description}</p>
            </div>
          }
        />
      )}
    </div>
  );
};

export default Gallery;
