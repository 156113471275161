import React, { useState } from "react";
import { services } from "../utils/styles"; // Array aggiornato con i servizi
import { ImageUpload } from "../components";

import { useDispatch } from "react-redux";
import {
  alertDanger,
  alertSuccess,
  alertNull,
} from "../context/actions/alertAction"; // Importa anche alertNull
import { buttonClick } from "../animations";
import { motion } from "framer-motion";
import { addNewRoom } from "../api"; // Funzione per aggiungere stanze via API
import { addRoom } from "../context/actions/roomActions"; // Azione Redux per aggiungere la stanza

const DbNewItem = () => {
  const [roomName, setRoomName] = useState("");
  const [roomType, setRoomType] = useState("");
  const [pricePerGuest, setPricePerGuest] = useState([
    { guests: 1, price: "" },
  ]);
  const [maxGuests, setMaxGuests] = useState("");
  const [description, setDescription] = useState("");
  const [amenities, setAmenities] = useState([]); // Corretto il tipo di stato
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [images, setImages] = useState([]);
  const [formErrors, setFormErrors] = useState({}); // Stato per gli errori di validazione

  const dispatch = useDispatch();

  const validateForm = () => {
    const errors = {};
    if (!roomName) errors.roomName = "Il nome della stanza è obbligatorio";
    if (!roomType) errors.roomType = "La tipologia è obbligatoria";
    if (pricePerGuest.some((p) => !p.price || p.price <= 0))
      errors.pricePerGuest = "Tutti i prezzi devono essere maggiori di zero";
    if (!maxGuests || maxGuests <= 0)
      errors.maxGuests = "Gli ospiti massimi devono essere maggiori di zero";
    if (!description) errors.description = "La descrizione è obbligatoria";
    if (!startDate || !endDate)
      errors.dates = "Le date di disponibilità sono obbligatorie";
    if (startDate > endDate)
      errors.dates = "La data di inizio deve essere prima della data di fine";

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const resetForm = () => {
    setRoomName("");
    setRoomType("");
    setPricePerGuest([{ guests: 1, price: "" }]);
    setMaxGuests("");
    setDescription("");
    setAmenities([]);
    setStartDate("");
    setEndDate("");
    setImages([]);
  };

  // Funzione per aggiungere la stanza
  const handleAddRoom = async () => {
    if (!validateForm()) return; // Validazione del modulo

    const newRoom = {
      roomName,
      roomType,
      pricePerGuest: pricePerGuest.map((p) => ({
        guests: p.guests,
        price: parseFloat(p.price),
      })),
      maxGuests: parseInt(maxGuests, 10),
      description,
      amenities,
      availability: {
        startDate,
        endDate,
      },
      images,
      createdAt: new Date().toISOString().split("T")[0],
      updatedAt: new Date().toISOString().split("T")[0],
    };

    const addedRoom = await addNewRoom(newRoom); // Chiama l'API per aggiungere la stanza
    if (addedRoom) {
      dispatch(addRoom(addedRoom)); // Aggiunge la stanza nello stato globale di Redux
      dispatch(alertSuccess("Stanza aggiunta con successo!"));
      setTimeout(() => dispatch(alertNull()), 5000);

      // Resetta alert dopo 5 secondi
      resetForm();
    } else {
      dispatch(alertDanger("Errore durante l'aggiunta della stanza"));
      setTimeout(() => dispatch(alertNull()), 5000); // Resetta alert dopo 5 secondi
    }
  };

  const handlePriceChange = (index, field, value) => {
    const updatedPrices = [...pricePerGuest];
    updatedPrices[index][field] = value;
    setPricePerGuest(updatedPrices);
  };

  const addPriceField = () => {
    setPricePerGuest([...pricePerGuest, { guests: "", price: "" }]);
  };

  const removePriceField = (index) => {
    const updatedPrices = pricePerGuest.filter((_, i) => i !== index);
    setPricePerGuest(updatedPrices);
  };

  // Funzione per gestire i servizi (amenities)
  const handleSelectAmenity = (amenity) => {
    setAmenities((prev) =>
      prev.includes(amenity)
        ? prev.filter((item) => item !== amenity)
        : [...prev, amenity]
    );
  };
  return (
    <div className="max-w-lg mx-auto bg-white shadow-lg rounded-lg p-6 mt-6">
      <div className="px-2 w-full text-center bg-headingColor shadow-xl py-2 rounded-lg mb-2 border-b-2 border-textColor">
        <h1 className="text-2xl font-medium text-primary">
          Crea Una Nuova Stanza
        </h1>
        <p className="text-sm text-primary">
          Compila il modulo per aggiungere una nuova stanza
        </p>
      </div>
      <h2 className="text-2xl font-normal text-headingColor mb-4">
        Aggiungi Nuova Stanza
      </h2>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleAddRoom();
        }}
        className="space-y-4"
      >
        <input
          type="text"
          placeholder="Nome Stanza"
          value={roomName}
          onChange={(e) => setRoomName(e.target.value)}
          className={`w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green ${
            formErrors.roomName ? "border-red-500" : ""
          }`}
        />
        {formErrors.roomName && (
          <p className="text-red-500 text-sm">{formErrors.roomName}</p>
        )}

        <input
          type="text"
          placeholder="Tipologia (e.g., Matrimoniale)"
          value={roomType}
          onChange={(e) => setRoomType(e.target.value)}
          className={`w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green ${
            formErrors.roomType ? "border-red-500" : ""
          }`}
        />
        {formErrors.roomType && (
          <p className="text-red-500 text-sm">{formErrors.roomType}</p>
        )}

        <div>
          <h3 className="text-lg font-medium text-headingColor mb-2">
            Prezzi per Ospiti
          </h3>
          {pricePerGuest.map((p, index) => (
            <div key={index} className="flex items-center space-x-2 mb-2">
              <input
                type="number"
                placeholder="Ospiti"
                value={p.guests}
                onChange={(e) =>
                  handlePriceChange(index, "guests", e.target.value)
                }
                className="w-1/3 px-4 py-2 border border-gray-300 rounded-lg"
              />
              <input
                type="number"
                placeholder="Prezzo"
                value={p.price}
                onChange={(e) =>
                  handlePriceChange(index, "price", e.target.value)
                }
                className="w-1/3 px-4 py-2 border border-gray-300 rounded-lg"
              />
              <button
                type="button"
                onClick={() => removePriceField(index)}
                className="text-red-500"
              >
                Rimuovi
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={addPriceField}
            className="text-green-500"
          >
            Aggiungi Ospite
          </button>
        </div>

        <input
          type="number"
          placeholder="Ospiti Massimi"
          value={maxGuests}
          onChange={(e) => setMaxGuests(e.target.value)}
          className={`w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green ${
            formErrors.maxGuests ? "border-red-500" : ""
          }`}
        />
        {formErrors.maxGuests && (
          <p className="text-red-500 text-sm">{formErrors.maxGuests}</p>
        )}

        <textarea
          placeholder="Descrizione"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className={`w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green ${
            formErrors.description ? "border-red-500" : ""
          }`}
        ></textarea>
        {formErrors.description && (
          <p className="text-red-500 text-sm">{formErrors.description}</p>
        )}

        <div>
          <h3 className="text-lg font-medium text-headingColor mb-2">
            Seleziona i Servizi
          </h3>
          <div className="grid grid-cols-2 gap-2">
            {services.map((service) => (
              <button
                key={service.id}
                onClick={() => handleSelectAmenity(service.title)}
                className={`flex items-center justify-center border rounded-lg py-2 px-4 transition duration-200 
            ${
              amenities.includes(service.title)
                ? "bg-purple-500 text-white"
                : "bg-gray-200 text-gray-700"
            }`}
              >
                <service.icon className="mr-2 text-lg" />{" "}
                {/* Aggiungi l'icona qui */}
                {service.title}
              </button>
            ))}
          </div>
        </div>

        <div>
          <label className="block mb-2">Data di Inizio</label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className={`w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green ${
              formErrors.dates ? "border-red-500" : ""
            }`}
          />
          <label className="block mb-2 mt-2">Data di Fine</label>
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className={`w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green ${
              formErrors.dates ? "border-red-500" : ""
            }`}
          />
          {formErrors.dates && (
            <p className="text-red-500 text-sm">{formErrors.dates}</p>
          )}
        </div>

        <ImageUpload setImages={setImages} images={images} />

        <motion.button
          type="submit"
          className="w-full bg-green text-white font-semibold py-2 rounded-lg hover:bg-green transition duration-200"
          {...buttonClick}
        >
          Aggiungi Stanza
        </motion.button>
      </form>
    </div>
  );
};

export default DbNewItem;
