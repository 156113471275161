import React from "react";
import { booking, airbnb, trip, expedia } from "../assets";

const PartnerSection = () => {
  return (
    <div className="w-full">
      <div className="text-center">
        {/* Headline */}
        <h2 className="text-3xl font-semibold text-headingColor mb-4">
          Siamo partner con le migliori piattaforme di prenotazione
        </h2>
        {/* Subheadline */}
        <p className="text-2xl text-gray-600 mb-8">
          Prenota il tuo soggiorno a B&B Piazza d’Armi anche su piattaforme come{" "}
          <span className="font-semibold">Booking, Airbnb, Expedia</span> e
          molte altre.
        </p>

        {/* Loghi dei partner */}
        <div className="flex justify-center flex-wrap">
          {/* Logo Booking */}
          <a
            href="https://www.booking.com/hotel/it/b-amp-b-piazza-d-armi.it.html?label=gen173nr-1BCAsocUIVYi1hbXAtYi1waWF6emEtZC1hcm1pSDNYBGhxiAEBmAEUuAEYyAEM2AEB6AEBiAIBqAIEuALV78K6BsACAdICJDc4N2UzNjYxLWQwNjMtNDY1My1hYTA2LTg0ZDliNjI0YjVlZtgCBeACAQ&sid=4a40d46b6125feb63d58f7e1ef418303&dist=0&group_adults=2&keep_landing=1&sb_price_type=total&type=total&"
            target="_blank"
            rel="noopener noreferrer"
            className="p-1 w-1/2 sm:w-1/2 md:w-1/2 lg:w-1/4 h-auto object-contain transition duration-300 hover:scale-110"
          >
            <img src={booking} alt="Booking" />
          </a>
          {/* Logo Airbnb */}
          <a
            href="https://www.airbnb.com"
            target="_blank"
            rel="noopener noreferrer"
            className="p-1 w-1/2 sm:w-1/2 md:w-1/2 lg:w-1/4 h-auto object-contain transition duration-300 hover:scale-110"
          >
            <img src={airbnb} alt="Airbnb" />
          </a>
          {/* Logo Expedia */}
          <a
            href="https://www.expedia.it/Lamezia-Terme-Hotel-BB-Piazza-DArmi.h111161485.Informazioni-Hotel"
            target="_blank"
            rel="noopener noreferrer"
            className="p-1 w-1/2 sm:w-1/2 md:w-1/2 lg:w-1/4 h-auto object-contain transition duration-300 hover:scale-110"
          >
            <img src={expedia} alt="Expedia" />
          </a>
          {/* Logo TripAdvisor */}
          {/* <a
                href="https://www.tripadvisor.com"
                target="_blank"
                rel="noopener noreferrer"
                className="p-1 w-1/2 sm:w-1/2 md:w-1/2 lg:w-1/4 h-auto object-contain transition duration-300 hover:scale-110"
              >
                <img
                  src={trip}
                  alt="TripAdvisor"
                />
              </a> */}
        </div>

        {/* Link aggiuntivo */}
      </div>
    </div>
  );
};

export default PartnerSection;
