import React, { useState } from "react";
import { Link } from "react-router-dom";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // Importa lo stile per il lightbox
import { Swiper, SwiperSlide } from "swiper/react"; // Importa lo stile di Swiper
import { sanTeodoro, teatroUmberto, piazzaMazzini, piazzaDarmiAnni90, piazzaDarmiAnni80, fontanaPiazzaDarmi, corsoNumistrannoAnni80, corsoNumistrannoAnni50, centroStoricoNicastro, cattedrale, castelloNormannoNotte, bastioneMalta2, bastioneMalta    } from "../assets";

const GallerySection = () => {
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [expandedDescription, setExpandedDescription] = useState(null);

  const images = [
    { url: sanTeodoro, alt: "Quartiere San Teodoro, Lamezia Terme", title: "Quartiere San Teodoro", description: "Il quartiere San Teodoro di Lamezia Terme è una zona vivace e dinamica, caratterizzata da un mix di tradizione e modernità, che riflette lo spirito della città." },
    { url: teatroUmberto, alt: "Teatro Umberto, Lamezia Terme", title: "Teatro Umberto", description: "Il Teatro Umberto è un'imponente struttura culturale di Lamezia Terme, conosciuto per la sua storicità e per gli eventi teatrali e musicali che ospita." },
    { url: piazzaMazzini, alt: "Piazza Mazzini, Lamezia Terme", title: "Piazza Mazzini", description: "Piazza Mazzini è una delle piazze principali di Lamezia Terme, cuore pulsante della vita cittadina, circondata da edifici storici e negozi." },
    { url: piazzaDarmiAnni90, alt: "Piazza D'Armi negli anni '90, Lamezia Terme", title: "Piazza D'Armi negli anni '90", description: "Piazza D'Armi negli anni '90, una delle piazze più suggestive di Lamezia Terme, che mostra come la città si sia evoluta nel tempo, mantenendo la sua autenticità." },
    { url: piazzaDarmiAnni80, alt: "Piazza D'Armi negli anni '80, Lamezia Terme", title: "Piazza D'Armi negli anni '80", description: "Un'immagine storica di Piazza D'Armi negli anni '80, che testimonia il dinamismo e le trasformazioni sociali della città durante quel periodo." },
    { url: fontanaPiazzaDarmi, alt: "Fontana di Piazza D'Armi, Lamezia Terme", title: "Fontana di Piazza D'Armi", description: "La fontana di Piazza D'Armi è uno degli elementi distintivi della piazza, un luogo di ritrovo e di bellezza che riflette la tradizione architettonica di Lamezia Terme." },
    { url: cattedrale, alt: "Cattedrale di Lamezia Terme", title: "Cattedrale di Lamezia Terme", description: "La Cattedrale di Lamezia Terme, dedicata a San Pietro e Paolo, è un simbolo della fede e della storia religiosa della città, con la sua maestosa facciata e la sua posizione centrale." },
    { url: corsoNumistrannoAnni80, alt: "Corso Numistranno negli anni '80, Lamezia Terme", title: "Corso Numistranno negli anni '80", description: "Corso Numistranno negli anni '80, una delle arterie principali di Lamezia Terme, che racconta la crescita della città attraverso gli anni." },
    { url: corsoNumistrannoAnni50, alt: "Corso Numistranno negli anni '50, Lamezia Terme", title: "Corso Numistranno negli anni '50", description: "Un'immagine di Corso Numistranno negli anni '50, che mostra il centro storico di Lamezia Terme in un periodo di grande trasformazione e sviluppo." },
    { url: corsoNumistrannoAnni50, alt: "Corso Numistranno negli anni '50, Lamezia Terme", title: "Corso Numistranno negli anni '50", description: "Una vista storica di Corso Numistranno negli anni '50, testimoniando le prime fasi di modernizzazione della città." },
    { url: centroStoricoNicastro, alt: "Centro storico di Nicastro, Lamezia Terme", title: "Centro storico di Nicastro", description: "Il centro storico di Nicastro è il cuore culturale di Lamezia Terme, con le sue stradine caratteristiche e il suo ricco patrimonio storico." },
    { url: castelloNormannoNotte, alt: "Castello Normanno di Lamezia Terme di notte", title: "Castello Normanno di notte", description: "Il Castello Normanno di Lamezia Terme di notte, una delle principali attrazioni turistiche della città, che offre una vista spettacolare e un'atmosfera magica." },
    { url: bastioneMalta2, alt: "Bastione di Malta, Lamezia Terme", title: "Bastione di Malta", description: "Il Bastione di Malta, una fortificazione storica che testimonia il passato difensivo della città, oggi simbolo di Lamezia Terme." },
    { url: bastioneMalta, alt: "Bastione di Malta, Lamezia Terme", title: "Bastione di Malta", description: "Il Bastione di Malta, con la sua imponente struttura, è un importante sito storico che rappresenta la resistenza della città nei secoli passati." },
];


  const openLightbox = (index) => {
    setCurrentIndex(index);
    setIsLightboxOpen(true);
  };

  const closeLightbox = () => {
    setIsLightboxOpen(false);
  };

  const handleToggleDescription = (index) => {
    setExpandedDescription(expandedDescription === index ? null : index);
  };

  return (
    <div className="w-full flex flex-col items-start mb-15">
      <div className="flex flex-col items-start justify-start gap-1 mb-6">
        <p className="text-3xl text-headingColor font-bold">Il Centro Storico</p>
        <p className="text-2xl text-gray-600">Esplora le bellezze storiche e culturali della nostra città, tra eleganza e tradizione.</p>
      </div>

      {/* Carosello Swiper */}
      <Swiper
        spaceBetween={30}  // Distanza tra le immagini
        slidesPerView={1}  // Quante immagini si vedono per volta
        loop={true}  // Ripetizione infinita
        autoplay={{ delay: 3000 }}  // Autoplay ogni 3 secondi
        className="w-full mb-6 rounded-xl"
      >
        {images.map((image, index) => {
          const shortDescription = image.description.slice(0, 40) + (image.description.length > 40 ? '...' : '');
          return (
            <SwiperSlide key={index}>
              <div className="w-full md:h-510 h-96 relative rounded-xl">
                <img
                  src={image.url}
                  alt={image.alt}
                  className="w-full h-full object-cover rounded-lg shadow-lg cursor-pointer hover:scale-105 transition-transform duration-300"
                  onClick={() => openLightbox(index)} // Aggiungi funzionalità Lightbox
                />
                <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 p-4">
                  <p className="text-white text-lg font-bold">{image.title}</p>
                  <p className="text-white">
                    {expandedDescription === index ? image.description : shortDescription}
                    {image.description.length > 40 && (
                      <span
                        onClick={() => handleToggleDescription(index)}
                        className="text-blue-500 bg-white p-1 rounded-md cursor-pointer ml-2"
                      >
                        {expandedDescription === index ? 'Leggi meno' : 'Leggi tutto'}
                      </span>
                    )}
                  </p>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>

      {/* Descrizione generale */}
      <p className="text-gray-700 mb-2 text-xl">Scopri il cuore pulsante della città, dove ogni angolo racconta una storia.</p>

      <Link
        to="/gallery"
        className="bg-headingColor text-white text-xl px-6 py-2 rounded-md hover:bg-primaryColor transition-colors"
      >
        Vai alla Gallery del Sito
      </Link>

      {/* Lightbox per visualizzare le immagini a schermo intero */}
      {isLightboxOpen && (
        <Lightbox
          mainSrc={images[currentIndex].url}
          nextSrc={images[(currentIndex + 1) % images.length].url}
          prevSrc={images[(currentIndex + images.length - 1) % images.length].url}
          onCloseRequest={closeLightbox}
          onMovePrevRequest={() =>
            setCurrentIndex((currentIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setCurrentIndex((currentIndex + 1) % images.length)
          }
          imageCaption={images[currentIndex].title} // Mostra il titolo dell'immagine
          imageAlt={images[currentIndex].alt} // Mostra il testo alternativo dell'immagine     
        />
      )}
    </div>
  );
};

export default GallerySection;
