import React, { useEffect, useState } from "react";
import { LoginBg, Logo } from "../assets";
import { LoginInput } from "../components";
import {
  FaEnvelope,
  FaLock,
  FaEye,
  FaEyeSlash,
  FcGoogle,
} from "../assets/icons"; // Aggiunto FaEye e FaEyeSlash
import { motion } from "framer-motion";
import { buttonClick } from "../animations";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUserDetails } from "../context/actions/userActions";

import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { app } from "../config/firebase.config";
import { validateUserJWTToken } from "../api";
import {
  alertInfo,
  alertWarning,
  alertNull,
} from "../context/actions/alertAction";

const Login = () => {
  const [userEmail, setUserEmail] = useState("");
  const [isSignUp, setIsSignUp] = useState(false);
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirm_password] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);

  const firebaseAuth = getAuth(app);
  const provider = new GoogleAuthProvider();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (user) {
      navigate("/", { replace: true });
    }
  }, [user]);

  const handleAlert = (message, type) => {
    // Dispatch alert
    if (type === "info") dispatch(alertInfo(message));
    if (type === "warning") dispatch(alertWarning(message));

    // Set a timeout to clear the alert
    setTimeout(() => {
      dispatch(alertNull());
    }, 2000); // 3 second timeout
  };

  // Accesso Utente con Google
  const loginWithGoogle = async () => {
    await signInWithPopup(firebaseAuth, provider).then((userCred) => {
      firebaseAuth.onAuthStateChanged((cred) => {
        if (cred) {
          cred.getIdToken().then((token) => {
            validateUserJWTToken(token).then((data) => {
              dispatch(setUserDetails(data));
            });
            navigate("/", { replace: true });
          });
        }
      });
    });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  };

  const signUpWithEmailPass = async () => {
    if (userEmail === "" || password === "" || confirm_password === "") {
      handleAlert("Compila Tutti i Campi", "info");
    } else if (!validateEmail(userEmail)) {
      handleAlert("Inserisci un'email valida", "warning");
    } else if (!validatePassword(password)) {
      handleAlert(
        "La password deve contenere almeno 8 caratteri, una lettera maiuscola, una minuscola e un numero",
        "warning"
      );
    } else if (password !== confirm_password) {
      handleAlert("Le Password non Coincidono", "warning");
    } else {
      setUserEmail("");
      setPassword("");
      setConfirm_password("");
      await createUserWithEmailAndPassword(
        firebaseAuth,
        userEmail,
        password
      ).then((userCred) => {
        firebaseAuth.onAuthStateChanged((cred) => {
          if (cred) {
            cred.getIdToken().then((token) => {
              validateUserJWTToken(token).then((data) => {
                dispatch(setUserDetails(data));
              });
              navigate("/", { replace: true });
            });
          }
        });
      });
    }
  };

  const signInWithEmailPass = async () => {
    if (userEmail === "" || password === "") {
      handleAlert("Compila Tutti i Campi", "warning");
    } else if (!validateEmail(userEmail)) {
      handleAlert("Inserisci un'email valida", "warning");
    } else if (!validatePassword(password)) {
      handleAlert(
        "La password deve contenere almeno 8 caratteri, una lettera maiuscola, una minuscola e un numero",
        "warning"
      );
    } else {
      await signInWithEmailAndPassword(firebaseAuth, userEmail, password).then(
        (userCred) => {
          firebaseAuth.onAuthStateChanged((cred) => {
            if (cred) {
              cred.getIdToken().then((token) => {
                validateUserJWTToken(token).then((data) => {
                  dispatch(setUserDetails(data));
                });
                navigate("/", { replace: true });
              });
            }
          });
        }
      );
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleForgotPassword = async () => {
    if (!validateEmail(userEmail)) {
      handleAlert("Inserisci un'email valida", "warning");
      return;
    }
    try {
      await sendPasswordResetEmail(firebaseAuth, userEmail);
      handleAlert("Email di reset inviata con successo", "info");
      setForgotPassword(false); // Torna al login dopo l'invio
    } catch (error) {
      handleAlert("Errore durante l'invio della mail di reset", "warning");
    }
  };

  return (
    <div className="w-screen h-screen relative overflow-hidden flex">
      <img
        src={LoginBg}
        className="w-full h-full object-cover absolute top-0 left-0"
        alt="bg-image"
      />
      <div className="flex flex-col items-center bg-bgDashboard w-[100%] md:w-508 h-full z-10 backdrop-blur-md p-4 px-4 py-12 gap-6">
        <div className="flex items-center justify-center gap-4 w-full">
          <img src={Logo} className="w-20" alt="logo" />
          <p className="text-headingColor font-semibold text-2xl">
            B&B Piazza d'Armi
          </p>
        </div>

        <p className="text-2xl font-semibold text-headingColor">Benvenuto</p>

        <p className="text-xl text-textColor -mt-6">
          {isSignUp ? "Registrati" : "Accedi"}
        </p>

        <div className="w-full flex flex-col items-center justify-center gap-6 px-4 md:px-12 py-4">
          {/* Email */}
          {!forgotPassword && (
            <LoginInput
              placeHolder={"Inserisci La Tua Mail"}
              icon={<FaEnvelope className="text-xl text-textColor" />}
              inputState={userEmail}
              inputStateFunc={setUserEmail}
              type="email"
              isSignUp={isSignUp}
            />
          )}

          {/* Password */}
          {!forgotPassword && (
            <div className="relative w-full">
              <LoginInput
                placeHolder={"Inserisci La Password"}
                icon={<FaLock className="text-xl text-textColor" />}
                inputState={password}
                inputStateFunc={setPassword}
                type={showPassword ? "text" : "password"}
                isSignUp={isSignUp}
              />
              <span
                className="absolute right-3 top-3 cursor-pointer text-xl text-textColor"
                onClick={toggleShowPassword}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
          )}

          {/* Confirm Password */}
          {isSignUp && !forgotPassword && (
            <div className="relative w-full">
              <LoginInput
                placeHolder={"Conferma La Password"}
                icon={<FaLock className="text-xl text-textColor" />}
                inputState={confirm_password}
                inputStateFunc={setConfirm_password}
                type={showPassword ? "text" : "password"}
                isSignUp={isSignUp}
              />
              <span
                className="absolute right-3 top-3 cursor-pointer text-xl text-textColor"
                onClick={toggleShowPassword}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
          )}

          {/* Forgot Password Link */}
          {!forgotPassword && (
            <motion.p
              className="text-sm text-textColor cursor-pointer"
              onClick={() => setForgotPassword(true)}
              whileHover={{ scale: 1.1 }}
              transition={buttonClick}
            >
              Hai dimenticato la password?
            </motion.p>
          )}

          {/* Forgot Password Form */}
          {forgotPassword && (
            <div className="flex flex-col items-center gap-4 w-full">
              <input
                type="email"
                className="px-4 py-2 w-full border border-gray-300 rounded-md"
                placeholder="Inserisci la tua email"
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
              />
              <button
                className="px-8 py-2 bg-emerald-500 text-white rounded-md"
                onClick={handleForgotPassword}
              >
                Invia Email di Reset
              </button>

              {/* Torna al login Button */}
              <motion.button
                className="px-12 py-2 mt-1 bg-headingColor text-white rounded-md"
                onClick={() => setForgotPassword(false)}
              >
                Torna al login
              </motion.button>
            </div>
          )}

          {/* Login / Signup Button */}
          {!forgotPassword && (
            <motion.button
              onClick={isSignUp ? signUpWithEmailPass : signInWithEmailPass}
              whileTap={{ scale: 0.97 }}
              className="w-full py-2 text-white bg-headingColor shadow-md rounded-md"
            >
              {isSignUp ? "Registrati" : "Accedi"}
            </motion.button>
          )}

          <span>Oppure</span>

          {/* Google Sign-In */}
          <motion.button
            className="flex gap-2 items-center justify-center py-2 px-4 rounded-md bg-white shadow-md w-full"
            whileTap={{ scale: 0.97 }}
            onClick={loginWithGoogle}
          >
            <FcGoogle className="text-2xl" />
            <span className="text-base font-semibold text-headingColor">Accedi con Google</span>
          </motion.button>

          {/* Switch to SignUp/SignIn */}
          <div className="flex gap-2">
            <span className="text-textColor">
              {isSignUp ? "Hai già un account?" : "Non hai un account?"}
            </span>
            <motion.p
              className="text-secondary cursor-pointer"
              onClick={() => setIsSignUp(!isSignUp)}
              whileHover={{ scale: 1.1 }}
              transition={buttonClick}
            >
              {isSignUp ? "Accedi" : "Registrati"}
            </motion.p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
